<template>
  <page-main
    title="Interests"
    nextText="Languages"
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/Languages')"
    @save="save()"
  >
    <template v-slot:aside>
      What are your passions or interests outside of your career or academics?
      <ul>
        <li>
          This is where you can add the interesting things you do outside of
          school or work that are appropriate to share with potential employers.
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold"
                  >List your personal interests:</span
                >
                Winter Sports; Reading; Playing Music; Travel
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #2:</span>
            <ul>
              <li>
                <span class="font-weight-bold"
                  >List your personal interests:</span
                >
                Winter Sports (snowboarding; ice skating); Playing Music
                (guitar)
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Career Interests"
            titleText="Select up to 5 areas of Career Interest:"
            :optionsList="baseCareerInterests"
            :selected.sync="careerInterests"
            tabindex="1"
            autofocus
            :maxSelections="25"
          ></r-multi-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Type here to add (one at at a time)"
            titleText="List your Personal Interests:"
            :optionsList="[]"
            :selected.sync="personalInterests"
            tabindex="2"
            :maxSelections="25"
          ></r-multi-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as Display from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "CareerInterest",
  data: () => ({}),
  components: {},
  methods: {
    save() {
      this.findDisplayValues();
      this.saveCareerInterests();
      this.savePersonalInterests();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("careerInterest", ["saveCareerInterests"]),
    ...mapActions("personalInterest", ["savePersonalInterests"]),
    findDisplayValues() {
      let careerValue = Display.BuildCommaString("", this.careerInterests);

      let personalInterestValue = Display.BuildCommaString(
        "",
        this.personalInterests
      );

      this.careerInterestsDisplay = careerValue;
      this.personalInterestsDisplay = personalInterestValue;

      return [careerValue, personalInterestValue];
    },
  },
  mounted() {},
  computed: {
    ...mapFields("careerInterest", [
      "careerInterests",
      "careerInterestsDisplay",
      "baseCareerInterests",
    ]),
    ...mapFields("personalInterest", [
      "personalInterests",
      "personalInterestsDisplay",
    ]),
    displayValues: function () {
      return this.findDisplayValues();
    },
    tips() {
      return [
        "Keep what you put here appropriate to share in a professional environment.",
        "Be aware of the industry where you’re looking for jobs and leave this information off your resume when appropriate.",
      ];
    },
  },
};
</script>
